import Vue from "vue";
import VueRouter from "vue-router";
import homeRouter from "./home";
import store from "../store/index";

Vue.use(VueRouter);

const routers = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: "/",
            redirect: () => {
                if (store.state.user) {
                    return "/home";
                } else {
                    return "/login";
                }
            },
        },
        {
            path: "/login", // 登录
            component: () => import("../login/login"),
            meta: {
                title: "登录",
                needLogin: false, // 是否需要登录才能访问
            },
        },
        {
            path: "/forget", // 忘记密码
            component: () => import("../login/forget"),
            meta: {
                title: "忘记密码",
                needLogin: false, // 是否需要登录才能访问
            },
        },
        {
            path: "/pdf", // 忘记密码
            component: () => import("../views/projectChildren/pdf"),
            meta: {
                title: "pdf",
                needLogin: false, // 是否需要登录才能访问
            },
        },
        {
            path: "/home",
            component: () => import("../views/Home"),
            children: homeRouter,
        },
    ],
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

routers.beforeEach((to, from, next) => {
    // 因为导航守卫会比 created 更早触发，所以要先获取本地数据
    let user = JSON.parse(window.localStorage.getItem("user"));
    if (!to.meta.needLogin) {
        next();
        return;
    }
    if (to.meta.needLogin && user) {
        next();
    } else {
        next({
            path: "/login",
        });
    }
});

export default routers;
