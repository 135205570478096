import axios from "axios";
import config from "../config/index";
import qs from "qs";
import store from "../store/index";

const service = axios.create({
  baseURL: config.urlStr,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
});
let Axios = async (url, data, method = "post", isQs = true,isBlob = false) => {
  return new Promise((resolve, reject) => {
    const options = {
      url,
      method,
    };
    if (method.toLowerCase() === "get") {
      options.params = data;
    } else {
      if (isQs) {
        data = qs.stringify(data);
      }
      options.data = data;
    }
    if (isBlob) options.responseType='blob'
    service(options)
      .then((res) => {
      if (options.responseType === 'blob'){
          resolve(res);
      } else if (Number(res.data.status) === 200) {
          resolve(res.data);
        } else if (res.data.status === 301) {
          sessionStorage.clear();
          localStorage.clear();
          store.user = null;
          window.location.href = "/login";
        } else {
          reject(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
export default Axios;
