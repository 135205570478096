// let urlStr = "http://192.168.1.17:8007"; // 本地
// let urlImgStr = "http://192.168.1.17:8007/"; //

// let urlStr = "http://oatest.bcpts.org.cn/"; // 线上测试
// let urlImgStr = "http://oatest.bcpts.org.cn/"; //
// let domain = "http://oatest.api.bcpts.org.cn/"; //

// let urlStr = "http://api.bcpts.org.cn"; // 线上正式 暂停使用
// let urlImgStr = "http://api.bcpts.org.cn/"; //

let domain = "http://oa.bcpts.org.cn/"; // 使用中

let urlStr = "http://api.emof.cn"; // 线上正式 emof  使用中
let urlImgStr = "http://api.emof.cn/"; //

// let domain = "http://oa.emof.cn/"; // 暂停使用

let zhangque = 13 //权限判断id
let zhennggehong = 14 //权限判断id
let chenjing = 22 //权限判断id
let localAnStore = [
    {
        key: "user",
        type: "localStorage",
    }, // 用户名
];
export default {
    urlStr,
    urlImgStr,
    domain,
    localAnStore,
    zhangque,
    zhennggehong,
    chenjing,
};
